import * as React from "react";
import { Frame } from "./Frame/Frame";
import { Image } from "./Image/Image";
import { FrameRowSubtitle, FrameRowTitle } from "./Typography/Typography";

interface Props {
  title: string;
  subtitle?: string;
  imageSrc: string;
  height: number;
  width: number;
  firstFrameColor?: string;
  secondFrameColor?: string;
  useVideoUri?: boolean;
  titleStyle?: object;
}

export const FrameRow = ({
  height,
  width,
  imageSrc,
  title,
  subtitle,
  firstFrameColor,
  secondFrameColor,
  useVideoUri,
  titleStyle,
}: Props) => {
  const container = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height,
    width,
  };
  const frameContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "40%",
  };
  const textContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    marginLeft: 25,
    height: "100%",
    width: "60%",
  };

  return (
    <div style={container}>
      <div style={frameContainer}>
        <Frame
          firstBackground={firstFrameColor ?? "#E57551"}
          secondBackground={secondFrameColor ?? "#F0AF8B"}
          frameOffest={10}
          frameWidth={5}
          showSoundOnButton={false}
        >
          {useVideoUri ? (
            <video
              style={{
                width: "100%",
                display: "block",
                maxHeight: 150,
                objectFit: "cover",
              }}
              src={imageSrc}
              muted
              autoPlay={true}
              loop={true}
              playsInline={false}
            />
          ) : (
            <Image
              src={imageSrc}
              alt="poster"
              style={{ width: width * 0.3, height: width * 0.45 }}
            />
          )}
        </Frame>
      </div>
      <div style={textContainer}>
        <FrameRowTitle style={titleStyle}>{title}</FrameRowTitle>
        {Boolean(subtitle) && <FrameRowSubtitle>{subtitle}</FrameRowSubtitle>}
      </div>
    </div>
  );
};
