import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/team/jon.mdx";
import React, {memo, useEffect, useRef, useState} from "react";
import {Container} from "../../components/Container";
import {Frame} from "../../components/Frame/Frame";
import {Image} from "../../components/Image/Image";
import Layout from "../../components/Layout";
import * as styles from "../../styles/team_page.module.css";
import {Video} from "../../components/Video/Video";
import {graphql} from "gatsby";
import {useAudioPlayback} from "../../components/AudioProvider";
export const TeamMemberPage = memo(({data}) => {
  const {stopSong} = useAudioPlayback();
  const [muted, setIsMuted] = useState(true);
  const [songNameVisible, setSongNameVisible] = useState(false);
  const profileAudioRef = useRef(null);
  const {name, activeDate, title, headshot, frameColors, videos, song, songName, quote, quoteAuthor} = data.mdx.frontmatter;
  useEffect(() => {
    stopSong(song);
  }, []);
  useEffect(() => {
    if (!muted) {
      setSongNameVisible(true);
      setTimeout(() => {
        setSongNameVisible(false);
      }, 3000);
    }
  }, [muted]);
  const pageContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    overflowX: "hidden"
  };
  const frameContainer = {
    marginTop: 55,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  };
  const songNameStyle = {
    color: "rgba(155,155,155,1)",
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 0,
    fontFamily: "raptor",
    fontStyle: "italic",
    fontSize: "13px",
    textAlign: "center"
  };
  const quoteStyle = {
    marginTop: 30,
    fontFamily: "raptor",
    fontStyle: "italic",
    fontSize: "20px",
    textAlign: "center",
    padding: 20
  };
  const quoteAuthorStyle = {
    fontFamily: "raptor",
    fontWeight: 600,
    fontStyle: "italic",
    fontSize: "18px",
    textAlign: "center",
    margin: 0
  };
  const soundOnStyle = {
    fontFamily: "raptor",
    fontWeight: 400,
    color: "rgba(155,155,155,1)",
    fontSize: "20px",
    textAlign: "center",
    cursor: "pointer"
  };
  const toggleMuteSong = () => {
    if (!profileAudioRef.current) {
      return;
    }
    if (profileAudioRef.current.muted) {
      profileAudioRef.current.muted = false;
      profileAudioRef.current.play();
      setIsMuted(false);
    } else {
      profileAudioRef.current.muted = true;
      profileAudioRef.current.pause();
      setIsMuted(true);
    }
  };
  return React.createElement(Layout, null, React.createElement("div", {
    style: pageContainer
  }, React.createElement(Container, null, React.createElement("div", {
    style: frameContainer,
    onClick: toggleMuteSong
  }, React.createElement(Frame, {
    firstBackground: frameColors[0],
    secondBackground: frameColors[1]
  }, React.createElement(Image, {
    src: headshot,
    alt: `${name}-headshot`
  })))), React.createElement("div", {
    style: {
      textAlign: "center",
      marginTop: 15
    }
  }, React.createElement("div", {
    style: soundOnStyle,
    onClick: toggleMuteSong
  }, muted ? "turn sound on" : "turn sound off"), React.createElement("div", {
    className: !muted ? styles.visible : styles.hidden
  }, React.createElement("div", {
    style: soundOnStyle
  }, "now playing:"), React.createElement("p", {
    style: songNameStyle
  }, songName))), React.createElement("audio", {
    ref: profileAudioRef,
    id: "profile_audio",
    loop: true,
    muted: muted
  }, React.createElement("source", {
    src: song
  })), React.createElement("h1", {
    className: styles.memberNameSolo
  }, name), React.createElement("h2", {
    className: styles.memberTitle,
    style: {
      marginTop: -5,
      marginBottom: 25
    }
  }, title), Boolean(activeDate) && React.createElement("h3", {
    className: styles.memberActiveDate,
    style: {
      marginTop: -20,
      marginBottom: 25
    }
  }, activeDate), React.createElement("div", {
    className: styles.videoWallContainer,
    style: {
      maxWidth: 750
    }
  }, videos.map((videoURL, index) => React.createElement("div", {
    key: index,
    className: styles.videoContainer
  }, React.createElement(Video, {
    src: videoURL,
    autoPlay: true,
    loop: true,
    playsInline: true,
    muted: true,
    style: {
      height: "100%"
    }
  }))), React.createElement("p", {
    style: quoteStyle
  }, "“" + quote + "”", React.createElement("p", {
    style: quoteAuthorStyle
  }, "- " + quoteAuthor)))));
});
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        name
        activeDate
        frameColors
        headshot
        videos
        song
        songName
        quote
        quoteAuthor
      }
    }
  }
`;
TeamMemberPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(TeamMemberPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
